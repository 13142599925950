<script>
import router from '../../../router';
import { userService } from '../../../helpers/authservice/user.service'
import Swal from 'sweetalert2';
import { useRoute } from 'vue-router';
import vueJwtDecode from 'vue-jwt-decode'

export default {
  setup() {
    !JSON.parse(localStorage.getItem('sis_config'))?.sis_usuario ? router.push({ path: '/login' }) : null
    !JSON.parse(localStorage.getItem('user')) ? router.push({ path: '/login' }) : null
    const route = useRoute()
    const { redirectFrom } = route.query

    const user = JSON.parse(localStorage.getItem('user'))
    localStorage.setItem('user', JSON.stringify({ ...user, token: '' }))
    const decodedRefershToken = vueJwtDecode.decode(user.refreshToken)
    if (Date.now() >= decodedRefershToken.exp * 1000) {
      router.push({
        name: 'signin-basic', query:
        {
          redirectFrom: redirectFrom,
          message: 'Sua sessão expirou, faça o login novamente para continuar.'
        }
      });
    }

    return {
      redirectFrom
    }
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('sis_config')).sis_usuario,
      refreshToken: JSON.parse(localStorage.getItem('user')).refreshToken,
      password: '',
      loading: false,
    }
  },
  methods: {
    async unlock() {
      this.loading = true
      const errors = await userService.unlock(this.refreshToken, this.password, this.redirectFrom)
      if (!errors.token) {
        this.loading = false
        Swal.fire({
          icon: "error",
          text: errors + '.',
          confirmButtonColor: "#34c38f",
          confirmButtonText: "OK",
        })
      }
    }
  }
}
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logos/ApoioBranco.png" alt="" height="100">
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">

              <div class="card-body p-4">
                <div class="text-center mt-2">
                </div>
                <div class="user-thumb text-center">
                  <img v-if="user.avatar" :src="user.avatar" class="rounded-circle img-thumbnail avatar-lg"
                    alt="thumbnail" :title="user.nome">
                  <img v-else src="@/assets/images/users/user-dummy-img.jpg"
                    class="rounded-circle img-thumbnail avatar-lg" alt="thumbnail" :title="user.nome">
                  <h5 class="font-size-15 mt-3">{{ user.nome }}</h5>
                </div>
                <div class="p-2 mt-4">
                  <div v-if="loading" class="mt-3 d-flex justify-content-center">
                    <b-spinner variant="success"></b-spinner>
                  </div>
                  <form v-else @submit.prevent="unlock">
                    <div class="mb-3">
                      <label class="form-label" for="userpassword">Senha</label>
                      <input v-model="password" type="password" class="form-control" id="userpassword"
                        placeholder="Coloque sua senha" required>
                    </div>
                    <div class="mb-2 mt-4">
                      <button class="btn btn-success w-100" type="submit">Continuar</button>
                    </div>
                  </form><!-- end form -->

                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div class="mt-4 text-center">
              <p class="mb-0">Não é você?
                <router-link to="/login" class="fw-semibold text-primary text-decoration-underline">Entrar</router-link>
              </p>
            </div>

          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                {{new Date().getFullYear()}} © Apoio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper --></template>